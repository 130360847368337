import merge from '~lodash.mergewith'
import * as CoreSdk from '~@sentry/core'
import { captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } from '~@sentry/browser'
import { replayIntegration } from '~@sentry/browser'
import { extraErrorDataIntegration, reportingObserverIntegration } from '~@sentry/integrations'
import { init } from '~@sentry/vue'

export { init }
export const SentrySdk = { ...CoreSdk, ...{ captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } }

/** @type {string[]} */
const DISABLED_INTEGRATION_KEYS = []

/**
 * @typedef {Parameters<typeof init>[0]} InitConfig
 * @param {import('@nuxt/types').Context} ctx
 * @return {Promise<InitConfig>}
 */
export function getConfig (ctx) {
  /** @type {InitConfig} */
  const config = {
    dsn:"https:\u002F\u002Ffeed1c1f78bb4eaca7810e5de52410bc@o331533.ingest.sentry.io\u002F1854211",
    environment:"PRODUCTION_AZURE_1",
    release:"lole-headless@19.7.0",
    denyUrls:[new RegExp("lolenuxt1\\.azurewebsites\\.net", "i")],
    replaysSessionSampleRate:0.1,
    replaysOnErrorSampleRate:0.1,
  }

  /** @type {NonNullable<InitConfig>['integrations']} */
  const resolvedIntegrations = [
    extraErrorDataIntegration(),
    reportingObserverIntegration({ types:["crash"] }),
    replayIntegration({ replaysSessionSampleRate:0.1,replaysOnErrorSampleRate:0.1 }),
  ]

  config.integrations = (defaultIntegrations) => {
    return [
      ...defaultIntegrations.filter(integration => !DISABLED_INTEGRATION_KEYS.includes(integration.name)),
      ...resolvedIntegrations,
    ]
  }
  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
